define("@clark/cms-ui/components/common/cms-section/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KMGed4TR",
    "block": "{\"symbols\":[\"@description\",\"&attrs\",\"@title\"],\"statements\":[[1,[28,\"cms-section/validate-header-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"section-header-container\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/header/styles\"]]]]]],[13,2],[8],[0,\"\\n  \"],[7,\"h2\",true],[11,\"class\",[29,[[28,\"typo\",[\"h2\"],null],\" \",[28,\"local-class\",[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/header/styles\"]]]]]],[10,\"data-test-cms-section-header-title\",\"\"],[8],[0,\"\\n    \"],[1,[28,\"markdown-to-html\",[[23,3,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"typo\",[\"c2\"],null],\" \",[28,\"local-class\",[\"description\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/header/styles\"]]]]]],[10,\"data-test-cms-section-header-description\",\"\"],[8],[0,\"\\n      \"],[1,[28,\"markdown-to-html\",[[23,1,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/cms-section/header/template.hbs"
    }
  });

  _exports.default = _default;
});