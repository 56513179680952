define("@clark/cms-ui/modifiers/valid-href", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  /**
   * Valid routes checks whether the `A` tag or element has href value
   * which is present in Contentful if not add a attribute which is handled
   * in `ember-href-to` to route to NGINX.
   */
  // eslint-disable-next-line ember/new-module-imports
  var _default = Ember._setModifierManager(function (owner) {
    return {
      createModifier: function createModifier() {},
      // eslint-disable-line @typescript-eslint/no-empty-function
      installModifier: function installModifier(state, element) {
        var validRoutes, routes, link, url;
        return regeneratorRuntime.async(function installModifier$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.prev = 0;
                validRoutes = owner.lookup('service:valid-routes');
                _context.next = 4;
                return regeneratorRuntime.awrap(validRoutes.routes);

              case 4:
                routes = _context.sent;
                link = element;
                url = link.getAttribute('href');
                url = url.charAt(url.length - 1) === '/' ? url.slice(0, -1) : url;

                if (url) {
                  if (!routes.includes(url)) {
                    link.dataset.hrefReplace = true;
                  }
                }

                _context.next = 14;
                break;

              case 11:
                _context.prev = 11;
                _context.t0 = _context["catch"](0);
                console.log(_context.t0);

              case 14:
              case "end":
                return _context.stop();
            }
          }
        }, null, null, [[0, 11]]);
      },
      updateModifier: function updateModifier() {},
      // eslint-disable-line @typescript-eslint/no-empty-function
      destroyModifier: function destroyModifier() {} // eslint-disable-line @typescript-eslint/no-empty-function

    };
  }, function ValidHrefModifier() {
    _classCallCheck(this, ValidHrefModifier);
  });

  _exports.default = _default;
});