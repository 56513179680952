define("@clark/cms-ui/components/heimat-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hBpYT2Im",
    "block": "{\"symbols\":[\"@onClose\",\"&default\"],\"statements\":[[4,\"modal-dialog\",null,[[\"overlayClass\",\"containerClass\"],[[28,\"local-class\",[\"heimat-overlay\"],[[\"from\"],[\"@clark/cms-ui/components/heimat-modal/styles\"]]],[28,\"local-class\",[\"heimat-modal-container\"],[[\"from\"],[\"@clark/cms-ui/components/heimat-modal/styles\"]]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"header-controls\"],[[\"from\"],[\"@clark/cms-ui/components/heimat-modal/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"header-controls-content\"],[[\"from\"],[\"@clark/cms-ui/components/heimat-modal/styles\"]]]]]],[8],[0,\"\\n      \"],[5,\"control-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"heimat-close-button\"],[[\"from\"],[\"@clark/cms-ui/components/heimat-modal/styles\"]]]]]],[12,\"data-test-heimat-modal-close\",\"\"]],[[\"@icon\",\"@onClick\"],[\"heimat-close\",[23,1,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[28,\"component\",[\"modal/header\"],null],[28,\"component\",[\"modal/body\"],null],[28,\"component\",[\"modal/footer\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/heimat-modal/template.hbs"
    }
  });

  _exports.default = _default;
});