define("@clark/cms-ui/components/modal/footer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-secondary-border": "#DADDEC",
    "c-grey-light": "#d6d6d6",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "modal-footer": "_modal-footer_1uc9yr"
  };
  _exports.default = _default;
});