define("@clark/cms-ui/components/button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-primary": "#2B6CDE",
    "cta-primary-hover": "#094FCB",
    "cta-primary-pressed": "#103C8A",
    "cta-primary-border": "transparent",
    "cta-secondary": "#FFFFFF",
    "cta-secondary-hover": "#094FCB",
    "cta-secondary-pressed": "#103C8A",
    "cta-secondary-border": "#DADDEC",
    "c-white": "#FFFFFF",
    "c-copy": "rgba(32, 49, 81, 0.7)",
    "border-radius-normal": "0.25rem",
    "border-width-thick": "2px",
    "shadow-faint": "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.02)",
    "spacing-extra-tight": "0.25rem",
    "spacing-tight": "0.5rem",
    "spacing-normal": "1rem",
    "line-height-body": "1.5rem",
    "font-medium": "'CeraRoundPro-Medium'",
    "icon-size": "1.5rem",
    "spinner-size": "1.5rem",
    "min-height": "3rem",
    "min-width": "9.5rem",
    "vertical-padding": "calc((3rem - 1.5rem) / 2)",
    "slim-min-height": "2rem",
    "slim-vertical-padding": "calc((2rem - 1.5rem) / 2)",
    "button": "_button_84h3hu",
    "primary": "_primary_84h3hu",
    "disabled": "_disabled_84h3hu",
    "loading": "_loading_84h3hu",
    "content": "_content_84h3hu",
    "icon": "_icon_84h3hu",
    "spinner": "_spinner_84h3hu",
    "size-slim": "_size-slim_84h3hu",
    "size-medium": "_size-medium_84h3hu",
    "icon-only": "_icon-only_84h3hu"
  };
  _exports.default = _default;
});