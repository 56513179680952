define("ember-href-to/helpers/href-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hrefTo = hrefTo;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function hrefTo(context, params) {
    var routing = Ember.getOwner(context).lookup("service:-routing");
    return routing.generateURL.apply(routing, _toConsumableArray(getParamsForGenerateURL(params)));
  }

  function getParamsForGenerateURL(params) {
    params = params.slice(); // create a copy

    var targetRouteName = params.shift(); // the first param is always the target route name

    var lastParam = params[params.length - 1]; // the last param might be queryParams

    var queryParams;

    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop().values;
    } else {
      queryParams = {};
    }

    var models = params; // the remainder are the models

    return [targetRouteName, models, queryParams];
  }

  var _default = Ember.Helper.extend({
    compute: function compute(params, namedArgs) {
      if (namedArgs.params) {
        return hrefTo(this, namedArgs.params);
      } else {
        return hrefTo(this, params);
      }
    }
  });

  _exports.default = _default;
});