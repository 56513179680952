define("@clark/cms-ui/components/modal/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zNK4sN7Q",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"modal-footer\"],[[\"from\"],[\"@clark/cms-ui/components/modal/footer/styles\"]]]]]],[12,\"role\",\"presentation\"],[12,\"data-test-modal-footer\",\"\"],[13,1],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/footer/template.hbs"
    }
  });

  _exports.default = _default;
});