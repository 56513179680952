define("@clark/cms-ui/styles/font", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "font-bold": "'CeraRoundPro-Bold'",
    "font-medium": "'CeraRoundPro-Medium'",
    "font-regular": "'CeraRoundPro-Regular'",
    "font-light": "'CeraRoundPro-Light'"
  };
  _exports.default = _default;
});