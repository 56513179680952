define("@clark/cms-ui/components/hero-small/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ylV+w8s3",
    "block": "{\"symbols\":[\"@title\",\"@description\",\"@imgSrc\"],\"statements\":[[1,[28,\"hero-small/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"container\"],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"row\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"col-12 col-md-8\"],null]],[8],[0,\"\\n      \"],[7,\"h1\",true],[11,\"class\",[29,[[28,\"typo\",[\"h1\"],null],\" m-0\"]]],[8],[0,\"\\n        \"],[1,[28,\"markdown-to-html\",[[23,1,[]]],[[\"class\"],[[28,\"markdown\",[\"reset-styles\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[11,\"class\",[29,[[28,\"typo\",[\"c1\"],null],\" \",[28,\"local-class\",[\"hero-small-description\"],[[\"from\"],[\"@clark/cms-ui/components/hero-small/styles\"]]]]]],[8],[0,\"\\n        \"],[1,[28,\"markdown-to-html\",[[23,2,[]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"image-container\"],[[\"from\"],[\"@clark/cms-ui/components/hero-small/styles\"]]]]]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[23,3,[]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/hero-small/template.hbs"
    }
  });

  _exports.default = _default;
});