define("@clark/cms-ui/utils/script-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchAndInjectScript = fetchAndInjectScript;

  /**
   *
   * @param source source for the script to be injected
   * @param replace flag to know if injected script tag needs to be removed/replaced
   */
  function injectScript(script) {
    var replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var trustpilotScriptNode = document.querySelector("script[src=".concat(script.source, "]"));

    if (replace && trustpilotScriptNode) {
      document.head.removeChild(trustpilotScriptNode);
    }

    if (script && !document.querySelector("script[src=".concat(script.source, "]"))) {
      document.head.append(script);
    }
  }
  /**
   *
   * @param source source for the script to be injected
   * @param replace flag to know if injected script tag needs to be removed/replaced
   */


  function fetchScript(source) {
    var replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.async = true;
      script.src = source;
      script.type = 'text/javascript';
      script.addEventListener('onload', resolve);
      script.addEventListener('error', reject);
      script.addEventListener('abort', function () {
        return reject(new Error("Aborted loading of '".concat(source, "'.")));
      });
      injectScript(script, replace);
    });
  }
  /**
   * @param source: url for the script to be injected
   * @param retryAttempts: number of attemps (default 3)
   * @param retryAttempts: number of attemps (default 3)
   *
   * Fetches and injects a 3rd party script in head. Has retry logic incase it
   * fails to load the script.
   */


  function fetchAndInjectScript(source) {
    var retryAttempts,
        i,
        replace,
        isLastAttempt,
        _args = arguments;
    return regeneratorRuntime.async(function fetchAndInjectScript$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            retryAttempts = _args.length > 1 && _args[1] !== undefined ? _args[1] : 3;
            i = 0;

          case 2:
            if (!(i < retryAttempts)) {
              _context.next = 16;
              break;
            }

            _context.prev = 3;
            replace = i > 0;
            return _context.abrupt("return", fetchScript(source, replace));

          case 8:
            _context.prev = 8;
            _context.t0 = _context["catch"](3);
            isLastAttempt = i + 1 === retryAttempts;

            if (!isLastAttempt) {
              _context.next = 13;
              break;
            }

            throw _context.t0;

          case 13:
            i++;
            _context.next = 2;
            break;

          case 16:
          case "end":
            return _context.stop();
        }
      }
    }, null, null, [[3, 8]]);
  }
});