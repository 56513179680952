define("@clark/cms-ui/helpers/spinner/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateSpinnerArgs = validateSpinnerArgs;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function validateSpinnerArgs(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        args = _ref2[0];

    var availableSizes = ['small', 'medium', 'large'];
    var availableColor = ['white', 'blue'];
    (false && !(!args.size || availableSizes.includes(args.size)) && Ember.assert("Invalid value for size ".concat(args.size, ", choose one of possible: ").concat(availableSizes.join(', ')), !args.size || availableSizes.includes(args.size)));
    (false && !(!args.color || availableColor.includes(args.color)) && Ember.assert("Invalid value for color ".concat(args.color, ", choose one of possible: ").concat(availableColor.join(', ')), !args.color || availableColor.includes(args.color)));
  }

  var _default = Ember.Helper.helper(validateSpinnerArgs);

  _exports.default = _default;
});