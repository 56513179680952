define("@clark/cms-ui/components/control-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "heimat-brand-primary": "#0439D8",
    "heimat-brand-primary-alt": "#87CDF9",
    "heimat-c-white": "#FFFFFF",
    "control-button": "_control-button_jknuzc",
    "color-white": "_color-white_jknuzc",
    "animation-ripple": "_animation-ripple_jknuzc",
    "ripple-effect": "_ripple-effect_jknuzc"
  };
  _exports.default = _default;
});