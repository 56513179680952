define("@clark/cms-ui/components/quick-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QVyo5PnS",
    "block": "{\"symbols\":[\"section\",\"item\",\"index\",\"&attrs\",\"@header\",\"@items\",\"@footer\"],\"statements\":[[5,\"common/cms-section\",[[12,\"data-test-quick-selection\",\"\"],[13,4]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[[12,\"class\",[28,\"typo\",[\"text-center\"],null]]],[[\"@title\",\"@description\"],[[23,5,[\"title\"]],[23,5,[\"description\"]]]]],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[[12,\"class\",[29,[[28,\"grid\",[\"row justify-content-lg-center\"],null],\" \",[28,\"local-class\",[\"quick-selection-container\"],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/styles\"]]]]]]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"grid\",[\"col-6 col-md-4 col-lg-3\"],null],\" \",[28,\"local-class\",[\"quick-selection-item\"],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/styles\"]]]]]],[8],[0,\"\\n        \"],[5,\"quick-selection/quick-selection-item\",[],[[\"@icon\",\"@title\"],[[23,2,[\"icon\"]],[23,2,[\"title\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,3,[]],2],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[28,\"grid\",[\"w-100 d-none d-lg-block\"],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2,3]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"footer\"]],[[12,\"class\",[28,\"grid\",[\"justify-content-center\"],null]]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"button\",[],[[\"@isPrimary\",\"@url\"],[true,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,7,[\"mobile\",\"href\"]],[23,7,[\"mobile\",\"desktop\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,7,[\"mobile\",\"text\"]],[23,7,[\"desktop\",\"text\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/quick-selection/template.hbs"
    }
  });

  _exports.default = _default;
});