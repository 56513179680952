define("@clark/cms-ui/modifiers/grid", ["exports", "@clark/cms-ui/styles/grid"], function (_exports, _grid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // eslint-disable-next-line ember/new-module-imports
  var _default = Ember._setModifierManager(function () {
    return {
      createModifier: function createModifier() {
        return {
          element: null,
          classNames: undefined
        };
      },
      installModifier: function installModifier(state, element, _ref) {
        var _element$classList;

        var _ref$positional = _slicedToArray(_ref.positional, 1),
            classNames = _ref$positional[0];

        // add the class to the element
        (_element$classList = element.classList).add.apply(_element$classList, _toConsumableArray(classNames.split(/\s+/g).map(function (c) {
          return _grid.default[c];
        }).filter(Boolean)));
      },
      updateModifier: function updateModifier() {// nothing
      },
      destroyModifier: function destroyModifier() {// nothing
      }
    };
  }, function GridModifier() {
    _classCallCheck(this, GridModifier);
  });

  _exports.default = _default;
});