define("@clark/cms-ui/components/modal/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E4pX8C36",
    "block": "{\"symbols\":[\"&attrs\",\"@title\",\"@description\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"local-class\",[\"modal-header\"],[[\"from\"],[\"@clark/cms-ui/components/modal/header/styles\"]]]]]],[12,\"role\",\"presentation\"],[13,1],[8],[0,\"\\n  \"],[7,\"h2\",true],[11,\"class\",[28,\"typo\",[\"h2 text-center\"],null]],[10,\"data-test-modal-header-title\",\"\"],[8],[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[11,\"class\",[28,\"typo\",[\"c2 text-center\"],null]],[10,\"data-test-modal-header-description\",\"\"],[8],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/header/template.hbs"
    }
  });

  _exports.default = _default;
});