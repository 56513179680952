define("@clark/cms-ui/components/heimat/input-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-primary": "#2B6CDE",
    "cta-primary-hover": "#094FCB",
    "cta-primary-pressed": "#103C8A",
    "cta-secondary": "#FFFFFF",
    "cta-secondary-hover": "#094FCB",
    "cta-secondary-pressed": "#103C8A",
    "heimat-brand-primary": "#0439D8",
    "heimat-brand-secondary": "#080F8C",
    "heimat-c-white": "#FFFFFF",
    "stroke-color": "#FFFFFF",
    "text-color": "#080F8C",
    "base-heimat-button": "_base-heimat-button_1wav6h _button-reset_17xnva _base-button_17xnva",
    "container": "_container_1wav6h",
    "input-button-heimat-primary": "_input-button-heimat-primary_1wav6h _base-heimat-button_1wav6h _button-reset_17xnva _base-button_17xnva",
    "input-button-heimat-secondary": "_input-button-heimat-secondary_1wav6h _base-heimat-button_1wav6h _button-reset_17xnva _base-button_17xnva",
    "post-icon": "_post-icon_1wav6h",
    "text": "_text_1wav6h",
    "icon": "_icon_1wav6h"
  };
  _exports.default = _default;
});