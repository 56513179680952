define("@clark/cms-ui/components/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XUpYBBZs",
    "block": "{\"symbols\":[\"@onClose\",\"@isHeimatRedesign\",\"&default\"],\"statements\":[[4,\"modal-dialog\",null,[[\"overlayClass\",\"containerClass\"],[[28,\"local-class\",[[28,\"if\",[[23,2,[]],\"heimat-overlay\",\"overlay\"],null]],[[\"from\"],[\"@clark/cms-ui/components/modal/styles\"]]],[28,\"local-class\",[[28,\"if\",[[23,2,[]],\"heimat-modal-container\",\"modal-container\"],null]],[[\"from\"],[\"@clark/cms-ui/components/modal/styles\"]]]]],{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"header-controls\"],[[\"from\"],[\"@clark/cms-ui/components/modal/styles\"]]]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"header-controls-content\"],[[\"from\"],[\"@clark/cms-ui/components/modal/styles\"]]]]]],[8],[0,\"\\n        \"],[5,\"control-button\",[[12,\"class\",[29,[[28,\"local-class\",[\"heimat-close-button\"],[[\"from\"],[\"@clark/cms-ui/components/modal/styles\"]]]]]]],[[\"@icon\",\"@onClick\"],[\"heimat-close\",[23,1,[]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",[29,[[28,\"local-class\",[\"close-button\"],[[\"from\"],[\"@clark/cms-ui/components/modal/styles\"]]]]]],[12,\"data-test-modal-close\",\"\"],[3,\"on\",[\"click\",[23,1,[]]]],[8],[0,\"\\n      \"],[5,\"icon\",[],[[\"@source\",\"@size\"],[\"cross-ui\",\"small\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[14,3,[[28,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[28,\"component\",[\"modal/header\"],null],[28,\"component\",[\"modal/body\"],null],[28,\"component\",[\"modal/footer\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/template.hbs"
    }
  });

  _exports.default = _default;
});