define("ember-simple-set-helper/helpers/set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function set(positional) {
    var _positional = _slicedToArray(positional, 3),
        target = _positional[0],
        key = _positional[1],
        maybeValue = _positional[2];

    (false && !(Boolean(target) && typeof key === 'string' || _typeof(key) === 'symbol') && Ember.assert('you must pass a path to {{set}}', Boolean(target) && typeof key === 'string' || _typeof(key) === 'symbol'));
    return positional.length === 3 ? function () {
      return Ember.set(target, key, maybeValue);
    } : function (value) {
      return Ember.set(target, key, value);
    };
  }

  var _default = Ember.Helper.helper(set);

  _exports.default = _default;
});