define("@clark/cms-ui/components/modal/body/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "modal-body": "_modal-body_1lerdr"
  };
  _exports.default = _default;
});