define("@clark/cms-ui/components/control-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fXI6iq4O",
    "block": "{\"symbols\":[\"@accessibilityLabel\",\"@icon\",\"&attrs\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[[28,\"local-class\",[[28,\"concat\",[\"control-button \",[23,0,[\"colorStyle\"]],\" \",[23,0,[\"animatedStyle\"]]],null]],[[\"from\"],[\"@clark/cms-ui/components/control-button/styles\"]]]]]],[12,\"aria-label\",[23,1,[]]],[12,\"data-test-control-button\",[23,2,[]]],[13,3],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"buttonAction\"]]]],[8],[0,\"\\n  \"],[5,\"icon\",[],[[\"@source\",\"@size\"],[[23,2,[]],\"large\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/control-button/template.hbs"
    }
  });

  _exports.default = _default;
});