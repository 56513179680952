define("@clark/cms-ui/components/heimat-modal/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "heimat-c-white-opaque": "rgba(255,255,255,0.5)",
    "heimat-image-active-width": "62.125rem",
    "border-radius-normal": "0.25rem",
    "overlay": "_overlay_hu4tj2 _overlay_130r74",
    "close-button": "_close-button_hu4tj2 _close-button_130r74",
    "modal-container": "_modal-container_hu4tj2 _modal-container_130r74",
    "header-controls": "_header-controls_hu4tj2",
    "header-controls-content": "_header-controls-content_hu4tj2",
    "heimat-modal-container": "_heimat-modal-container_hu4tj2",
    "container": "_container_hu4tj2",
    "heimat-overlay": "_heimat-overlay_hu4tj2",
    "modal-header": "_modal-header_hu4tj2",
    "heimat-close-button": "_heimat-close-button_hu4tj2"
  };
  _exports.default = _default;
});