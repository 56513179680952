define("@clark/cms-ui/components/crosslink/crosslink-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dugdDfsX",
    "block": "{\"symbols\":[\"@link\",\"&attrs\",\"@title\",\"@content\"],\"statements\":[[1,[28,\"crosslink-item/validate-args\",[[23,0,[\"args\"]]],null],false],[0,\"\\n\\n\"],[5,\"unstyled-link\",[[12,\"class\",[29,[[28,\"local-class\",[\"crosslink-item\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/crosslink-item/styles\"]]]]]],[13,2],[12,\"data-test-crosslink-item\",\"\"],[3,\"valid-href\"]],[[\"@url\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"icon-container\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/crosslink-item/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"icon\",[[12,\"class\",[29,[[28,\"local-class\",[\"icon\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/crosslink-item/styles\"]]]]]]],[[\"@source\",\"@size\"],[\"star\",\"extra-large\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h4\",true],[11,\"class\",[28,\"typo\",[\"h4\"],null]],[8],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[1,[23,4,[]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"local-class\",[\"crosslink-chevron\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/crosslink-item/styles\"]]]]]],[8],[0,\"\\n    \"],[5,\"icon\",[],[[\"@source\",\"@size\"],[\"chevron-right\",\"extra-large\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/crosslink/crosslink-item/template.hbs"
    }
  });

  _exports.default = _default;
});